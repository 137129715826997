import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { updateFragment } from "~/graphql/cache";
import { AGREEMENT_FRAGMENT } from "~/graphql/fragments/agreement";
import { AGREEMENT_SUMMARY_FRAGMENT } from "~/graphql/fragments/agreementSummary";
import {
  AgreementFragment,
  AgreementSummaryFragment,
  Status,
  UnarchiveAgreementInput,
} from "~/graphql/generated";
import { UNARCHIVE_AGREEMENT } from "~/graphql/mutations/agreement/unarchiveAgreement";
import { GET_ARCHIVED_AGREEMENTS } from "~/graphql/queries/agreement/getArchivedAgreements";

const useSetupUnarchiveAgreement = () => {
  const [unarchiveAgreementMutation] = useMutation(UNARCHIVE_AGREEMENT, {
    update(cache, { data }) {
      if (!data?.unarchiveAgreement._id) {
        throw new Error(
          "mutation to unarchive agreement could not update Apollo cache."
        );
      }

      cache.modify({
        fields: {
          archivedAgreements() {
            const {
              unarchiveAgreement: { _id: unarchivedId },
            } = data;

            const cachedArchive =
              cache.readQuery({
                query: GET_ARCHIVED_AGREEMENTS,
              })?.archivedAgreements || [];

            cache.writeQuery({
              query: GET_ARCHIVED_AGREEMENTS,
              data: {
                archivedAgreements: cachedArchive.filter(
                  ({ _id }) => _id !== unarchivedId
                ),
              },
            });

            updateFragment<AgreementFragment>(
              cache,
              "Agreement",
              AGREEMENT_FRAGMENT,
              { status: Status.Active }
            );

            updateFragment<AgreementSummaryFragment>(
              cache,
              "AgreementSummary",
              AGREEMENT_SUMMARY_FRAGMENT,
              { status: Status.Active }
            );
          },
        },
      });
    },
  });

  const unarchiveAgreement = useCallback(
    async (input: UnarchiveAgreementInput) => {
      const { data, errors } = await unarchiveAgreementMutation({
        variables: {
          input,
        },
      });
      return { unarchiveAgreement: data?.unarchiveAgreement, errors };
    },
    [unarchiveAgreementMutation]
  );

  return unarchiveAgreement;
};

export default useSetupUnarchiveAgreement;
