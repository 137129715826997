import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import type {
  CreateDraftMutation,
  CreateDraftMutationVariables,
} from "~/graphql/generated";

export const CREATE_DRAFT: TypedDocumentNode<
  CreateDraftMutation,
  CreateDraftMutationVariables
> = gql`
  mutation CreateDraft($input: CreateDraftInput!) {
    createDraft(input: $input) {
      _id
      version
    }
  }
`;
