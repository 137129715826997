import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import type { DeleteDraftInput } from "~/graphql/generated";
import { DELETE_DRAFT } from "~/graphql/mutations/agreement/deleteDraft";
import { GET_DRAFTS } from "~/graphql/queries/agreement/getDrafts";

const useSetupDeleteDraft = () => {
  const [deleteDraftMutation] = useMutation(DELETE_DRAFT, {
    update(cache, { data }) {
      if (!data?.deleteDraft) {
        throw new Error(
          "mutation to delete draft could not update Apollo cache."
        );
      }

      const { deleteDraft } = data;

      cache.modify({
        fields: {
          drafts() {
            const cachedData = cache.readQuery({
              query: GET_DRAFTS,
            });

            if (!cachedData?.drafts) {
              return;
            }

            const newDrafts = {
              drafts: cachedData.drafts.filter(
                ({ _id }) => _id !== deleteDraft._id
              ),
            };

            cache.writeQuery({
              query: GET_DRAFTS,
              data: newDrafts,
            });
          },
        },
      });
    },
  });

  const deleteDraft = useCallback(
    async (input: DeleteDraftInput) => {
      const { data, errors } = await deleteDraftMutation({
        variables: {
          input,
        },
      });
      return { deleteDraft: data?.deleteDraft, errors };
    },
    [deleteDraftMutation]
  );

  return deleteDraft;
};

export default useSetupDeleteDraft;
