import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { updateFragment } from "~/graphql/cache";
import { AGREEMENT_FRAGMENT } from "~/graphql/fragments/agreement";
import { AGREEMENT_SUMMARY_FRAGMENT } from "~/graphql/fragments/agreementSummary";
import {
  AgreementFragment,
  AgreementSummaryFragment,
  ArchiveAgreementInput,
  Status,
} from "~/graphql/generated";
import { ARCHIVE_AGREEMENT } from "~/graphql/mutations/agreement/archiveAgreement";
import { GET_AGREEMENTS } from "~/graphql/queries/agreement/getAgreements";

const useSetupArchiveAgreement = () => {
  const [archiveAgreementMutation] = useMutation(ARCHIVE_AGREEMENT, {
    update(cache, { data }) {
      if (!data?.archiveAgreement._id) {
        throw new Error(
          "mutation to archive agreement could not update Apollo cache."
        );
      }

      cache.modify({
        fields: {
          agreements() {
            const {
              archiveAgreement: { _id: archivedId },
            } = data;

            const cachedActiveAgreementSummaries =
              cache.readQuery({
                query: GET_AGREEMENTS,
              })?.agreements || [];

            cache.writeQuery({
              query: GET_AGREEMENTS,
              data: {
                agreements: cachedActiveAgreementSummaries.filter(
                  ({ _id }) => _id !== archivedId
                ),
              },
            });

            updateFragment<AgreementFragment>(
              cache,
              "Agreement",
              AGREEMENT_FRAGMENT,
              { status: Status.Archived }
            );

            updateFragment<AgreementSummaryFragment>(
              cache,
              "AgreementSummary",
              AGREEMENT_SUMMARY_FRAGMENT,
              { status: Status.Archived }
            );
          },
        },
      });
    },
  });

  const archiveAgreement = useCallback(
    async (input: ArchiveAgreementInput) => {
      const { data, errors } = await archiveAgreementMutation({
        variables: {
          input,
        },
      });
      return { archiveAgreement: data?.archiveAgreement, errors };
    },
    [archiveAgreementMutation]
  );

  return archiveAgreement;
};

export default useSetupArchiveAgreement;
