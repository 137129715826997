import { gql } from "@apollo/client";

export const INTERACTION_FRAGMENT = gql`
  fragment Interaction on Interaction {
    content
    _id
    createdAt
    updatedAt
    propertyFor
    type
    range {
      anchor {
        path
        offset
      }
      focus {
        path
        offset
      }
    }
    user {
      displayName
      _id
      avatar
    }
    proposalId
    response {
      content
      createdAt
      updatedAt
      user {
        avatar
        displayName
        _id
      }
    }
    reactions {
      user {
        _id
        displayName
      }
      type
    }
    finalizeCycle
    acknowledgedByProposer
  }
`;
