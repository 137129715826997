import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import { DRAFT_FRAGMENT } from "~/graphql/fragments/draft";
import type {
  UpdateDraftMutation,
  UpdateDraftMutationVariables,
} from "~/graphql/generated";

export const UPDATE_DRAFT: TypedDocumentNode<
  UpdateDraftMutation,
  UpdateDraftMutationVariables
> = gql`
  mutation UpdateDraft($_id: String!, $input: DraftInput!) {
    updateDraft(_id: $_id, input: $input) {
      ...Draft
    }
  }
  ${DRAFT_FRAGMENT}
`;
