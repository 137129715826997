import Snackbar from "../Snackbar";

/**
 * Now that this wraps the more versatile Snackbar component, we can eventually
 * replace all instances of this component with `<Snackbar variant="error"`
 */
const ErrorSnackbar: React.FC<{
  open: boolean;
  onClose(): void;
  bottomOffset?: number;
}> = (props) => {
  return <Snackbar {...props} variant="error" />;
};

export default ErrorSnackbar;
