import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

// import { DRAFT_FRAGMENT } from "~/graphql/fragments/draft";
import type {
  DraftFromExistingAgreementMutation,
  DraftFromExistingAgreementMutationVariables,
} from "~/graphql/generated";

export const DRAFT_FROM_EXISTING_AGREEMENT: TypedDocumentNode<
  DraftFromExistingAgreementMutation,
  DraftFromExistingAgreementMutationVariables
> = gql`
  mutation DraftFromExistingAgreement(
    $input: DraftFromExistingAgreementInput!
  ) {
    draftFromExistingAgreement(input: $input) {
      _id
      workspace {
        _id
      }
    }
  }
`;
