import { gql } from "@apollo/client";

import { GOVERNS_FRAGMENT } from "~/graphql/fragments/governs";
import { INTERACTION_FRAGMENT } from "~/graphql/fragments/interaction";
import { PARTICIPANT_ROLE_FRAGMENT } from "~/graphql/fragments/ParticipantRole";

export const PROPOSAL_FRAGMENT = gql`
  fragment Proposal on Proposal {
    _id
    context
    parent {
      version
      activeVersion
      roles {
        ...ParticipantRole
      }
      expirationDate
    }
    activeVersion
    isStale
    smartEvents {
      eventAction
    }
    status
    round
    version
    visibility
    activePlayer
    expirationDuration
    nextAdvancement
    feedbackFrequency
    fastTrack
    agreementType
    agreementProperties {
      predefined {
        agreementType
        feedbackFrequency
        expirationDuration
      }
    }
    inviteLink
    inviteLinkParticipationRole
    workingDocument {
      title
      subtitle
      body
    }
    workspace {
      _id
      name
    }
    interactions {
      ...Interaction
    }
    participants {
      userId
      user {
        _id
        displayName
        avatar
      }
      role
      reaction
      status
      roundStatus {
        understand
        improve
        finalize
      }
      decision
      onBehalfOf {
        ...Governs
      }
    }
    governs {
      ...Governs
    }
    roles {
      ...ParticipantRole
    }
    proposer {
      _id
      displayName
      avatar
      email
    }
    documents {
      title
      subtitle
      body
      round
    }
    finalizeCycle
  }
  ${INTERACTION_FRAGMENT}
  ${GOVERNS_FRAGMENT}
  ${PARTICIPANT_ROLE_FRAGMENT}
`;
