import { gql } from "@apollo/client";

import { PARTICIPANT_ROLE_FRAGMENT } from "~/graphql/fragments/ParticipantRole";

export const DRAFT_FRAGMENT = gql`
  fragment Draft on Draft {
    _id
    context
    proposalVersion
    activeVersion
    draftURL
    status
    version
    visibility
    template
    feedbackFrequency
    agreementType
    expirationDuration
    discoveryMetadata {
      creatorProfileId
      worksWellWith
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
    agreementProperties {
      predefined {
        agreementType
        feedbackFrequency
        expirationDuration
      }
    }
    draftVisibleToWorkspace
    workspace {
      _id
    }
    workingDocument {
      title
      subtitle
      body
    }
    parent {
      activeVersion
      version
      roles {
        ...ParticipantRole
      }
    }
    otherUsersDraftingFromParent {
      _id
      displayName
    }
    proposer {
      _id
      displayName
      avatar
      email
    }
  }

  ${PARTICIPANT_ROLE_FRAGMENT}
`;
