import { gql } from "@apollo/client";

export const DRAFT_SUMMARY_FRAGMENT = gql`
  fragment DraftSummary on Draft {
    _id
    status
    template
    updatedAt
    version
    visibility
    parent {
      _id
      workingDocument {
        title
      }
    }
    workingDocument {
      title
      subtitle
      body
    }
    proposerText {
      title
      subtitle
      body
    }
    discoveryMetadata {
      creatorProfileId
      worksWellWith
      description
      descriptionText
      audience
      audienceText
      orgType
      teamType
    }
  }
`;
