import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import type { CreateDraftInput } from "~/graphql/generated";
import { CREATE_DRAFT } from "~/graphql/mutations/agreement/createDraft";

const useSetupCreateDraft = () => {
  const [createDraftMutation] = useMutation(CREATE_DRAFT);

  const createDraft = useCallback(
    async (input: CreateDraftInput) => {
      const { data, errors } = await createDraftMutation({
        variables: {
          input,
        },
      });

      return { data: data?.createDraft, errors };
    },
    [createDraftMutation]
  );

  return createDraft;
};

export default useSetupCreateDraft;
