import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import styled from "styled-components";

import AddIcon from "~/assets/icons/add.svg";
import CopyIcon from "~/assets/icons/copy.svg";
import { UseTemplateButton } from "~/browser/explore/components/UseTemplateButton";
import DefaultButton from "~/components/Button";
import { useCreateDraft } from "~/components/CreateAgreementLink/useCreateDraft";
import ShiftBy from "~/components/ShiftBy";
import Spacer from "~/components/Spacer";
import { useUserContext } from "~/contexts/UserContext";
import { useDraftFromExistingAgreement } from "~/graphql/hooks/draft/useDraftFromExistingAgreement";
import useAgreementSlug from "~/hooks/useAgreementSlug";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

export const Button = styled(DefaultButton)`
  display: flex;
  align-items: center;
  margin-top: 0;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
`;

export default function CreateAgreementLink() {
  const { pathname } = useRouter();
  const { authenticated, user } = useUserContext();
  const workspaceSlug = useWorkspaceSlug();

  // don't display new agreement link on a draft or proposal, or settings and members
  const displayNewAgreementLink = !(
    pathname.endsWith(`/drafts/new`) ||
    pathname.endsWith(`/drafts/[slug]`) ||
    pathname.endsWith(`/proposals/[slug]`) ||
    pathname.endsWith(`/settings`) ||
    pathname.endsWith(`/members`)
  );

  if (!displayNewAgreementLink || !authenticated) {
    return null;
  }

  if (user.guestOfWorkspaces?.some(({ _id }) => _id === workspaceSlug)) {
    return null;
  }

  // if user is authenticated, default to a new agreement link
  let Component = NewAgreementLink;

  // if the user is not in this workspace, provide them with duplicate agreement
  if (
    user.workspaces &&
    !user.workspaces.some((workspace) => workspace._id === workspaceSlug) &&
    !pathname.includes("/explore")
  ) {
    Component = DuplicateAgreementLink;
  }

  return (
    <Spacer right={1}>
      <Component />
    </Spacer>
  );
}

export function NewAgreementLink() {
  const { createDraftAndRedirect } = useCreateDraft();

  const handleClick = useCallback(() => {
    createDraftAndRedirect();
  }, [createDraftAndRedirect]);

  return (
    <Button onClick={handleClick}>
      <Spacer right={0.5}>
        <ShiftBy y={1.5}>
          <AddIcon width="13" height="13" />
        </ShiftBy>
      </Spacer>
      New Agreement
    </Button>
  );
}

function DuplicateAgreementLink() {
  const agreementSlug = useAgreementSlug();

  const { draftFromExistingAgreement } = useDraftFromExistingAgreement();
  const { pathname, push, query } = useRouter();
  const { user } = useUserContext();

  const handleDuplicateAgreement = useCallback(
    async ({ workspaceId }: { workspaceId: string }) => {
      // getting workspace from this user's first workspace
      // TODO: when we add multi workspace, handle starting a draft
      //    in selected workspace or in the current workspace (if that exists)
      const { data } = await draftFromExistingAgreement({
        _id: agreementSlug,
        workspaceId,
        fromCollectionId: query.collectionId as string,
      });
      if (data) {
        await push(`/${data.workspace._id}/drafts/${data._id}`);
      }
    },
    [draftFromExistingAgreement, agreementSlug, push, query.collectionId]
  );
  const isTemplate = useMemo(() => {
    return pathname.includes("/templates/");
  }, [pathname]);

  const buttonText = isTemplate ? "Use Template" : "Duplicate Agreement";

  return isTemplate ? (
    <UseTemplateButton templateId={agreementSlug} />
  ) : (
    <Button
      onClick={() => {
        if (!user.workspaces?.length) {
          window.alert(
            "You need to be a member of a workspace to draft agreements"
          );
          return;
        }
        handleDuplicateAgreement({ workspaceId: user.workspaces[0]._id });
      }}
      variant={isTemplate ? "speech" : "primary"}
    >
      <Spacer right={0.5}>
        <ShiftBy y={1.5}>
          <CopyIcon width="13" height="13" />
        </ShiftBy>
      </Spacer>
      {buttonText}
    </Button>
  );
}
