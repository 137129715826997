import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { useCallback } from "react";

import type {
  DraftInput,
  UpdateDraftMutation,
  UpdateTemplateMutation,
} from "~/graphql/generated";
import { UPDATE_DRAFT } from "~/graphql/mutations/agreement/updateDraft";
import { UPDATE_TEMPLATE } from "~/graphql/mutations/agreement/updateTemplate";

const useSetupUpdateDraft = () => {
  const [updateDraftMutation] = useMutation(UPDATE_DRAFT);
  const [updateTemplateMutation] = useMutation(UPDATE_TEMPLATE);
  const { query } = useRouter();

  const updateDraft = useCallback(
    async (input: DraftInput) => {
      const mutation = query.template
        ? updateTemplateMutation
        : updateDraftMutation;
      const { data, errors } = await mutation({
        variables: {
          _id: input._id ?? "",
          input,
        },
      });

      return {
        data: query.template
          ? (data as UpdateTemplateMutation)?.updateTemplate
          : (data as UpdateDraftMutation)?.updateDraft,
        errors,
      };
    },
    [query.template, updateDraftMutation, updateTemplateMutation]
  );

  return updateDraft;
};

export default useSetupUpdateDraft;
