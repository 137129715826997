import { gql } from "@apollo/client";

import { PARTICIPANT_ROLE_FRAGMENT } from "~/graphql/fragments/ParticipantRole";

export const AGREEMENT_SUMMARY_FRAGMENT = gql`
  fragment AgreementSummary on Agreement {
    _id
    status
    agreementType
    version
    needsFeedback
    needsAcknowledgment
    workingDocument {
      title
      subtitle
      body
    }
    workspace {
      name
      _id
    }
    proposer {
      _id
      displayName
      avatar
    }
    governs {
      users {
        _id
        displayName
        avatar
      }
      groups {
        _id
        name
        type
        icon
        description
      }
    }
    roles {
      ...ParticipantRole
    }
  }
  ${PARTICIPANT_ROLE_FRAGMENT}
`;
