import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { MARK_DRAFT_AS_TEMPLATE } from "~/graphql/mutations/agreement/markDraftAsTemplate";

const useSetupMarkDraftAsTemplate = () => {
  const [markDraftAsTemplate] = useMutation(MARK_DRAFT_AS_TEMPLATE);

  const updateTemplate = useCallback(
    async ({ _id }: { _id: string }) => {
      const { data, errors } = await markDraftAsTemplate({
        variables: { _id },
      });

      return {
        data: data?.markDraftAsTemplate,
        errors,
      };
    },
    [markDraftAsTemplate]
  );

  return updateTemplate;
};

export default useSetupMarkDraftAsTemplate;
