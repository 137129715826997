import type { ApolloCache, DocumentNode } from "@apollo/client";

export function updateFragment<T extends { _id: string }>(
  cache: ApolloCache<any>, // eslint-disable-line
  typename: string,
  fragment: DocumentNode,
  data: Partial<T>
) {
  const id = `${typename}:${data._id}`;
  const cachedFragment: T | null = cache.readFragment({ id, fragment });

  if (cachedFragment) {
    cache.writeFragment({
      id,
      fragment,
      data: { ...cachedFragment, ...fragment },
    });
  }
}
