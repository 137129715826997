import type { FC } from "react";

// Thanks to Kyle Shevlin for recommending using this component from a blog post
// by Josh Comeau https://www.joshwcomeau.com/css/pixel-perfection/
const ShiftBy: FC<{ inline?: boolean; x?: number; y?: number }> = ({
  inline = false,
  x = 0,
  y = 0,
  children,
  ...delegated
}) => {
  return (
    <div
      {...delegated}
      style={{
        display: inline ? "inline-block" : "block",
        transform: `translate(${x}px, ${y}px)`,
      }}
    >
      {children}
    </div>
  );
};

export default ShiftBy;
