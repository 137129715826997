import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import type {
  ArchiveAgreementMutation,
  MutationArchiveAgreementArgs,
} from "~/graphql/generated";

export const ARCHIVE_AGREEMENT: TypedDocumentNode<
  ArchiveAgreementMutation,
  MutationArchiveAgreementArgs
> = gql`
  mutation ArchiveAgreement($input: ArchiveAgreementInput!) {
    archiveAgreement(input: $input) {
      _id
    }
  }
`;
