import type { TypedDocumentNode } from "@apollo/client";
import { gql } from "@apollo/client";

import { AGREEMENT_FRAGMENT } from "~/graphql/fragments/agreement";
import { PROPOSAL_FRAGMENT } from "~/graphql/fragments/proposal";
import type {
  ProposeAgreementMutation,
  ProposeAgreementMutationVariables,
} from "~/graphql/generated";

export const PROPOSE_AGREEMENT: TypedDocumentNode<
  ProposeAgreementMutation,
  ProposeAgreementMutationVariables
> = gql`
  mutation ProposeAgreement($_id: String!, $input: ProposeDraftInput!) {
    proposeAgreement(_id: $_id, input: $input) {
      ...Proposal
      ...Agreement
    }
  }
  ${PROPOSAL_FRAGMENT}
  ${AGREEMENT_FRAGMENT}
`;
