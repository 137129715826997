import type { TypedDocumentNode } from "@apollo/client";
import { gql } from "@apollo/client";

import { AGREEMENT_SUMMARY_FRAGMENT } from "~/graphql/fragments/agreementSummary";
import type {
  GetAgreementsQuery,
  GetAgreementsQueryVariables,
} from "~/graphql/generated";

export const GET_AGREEMENTS: TypedDocumentNode<
  GetAgreementsQuery,
  GetAgreementsQueryVariables
> = gql`
  query GetAgreements($agreementsParams: AgreementsParams!) {
    agreements(params: $agreementsParams) {
      ...AgreementSummary
      expirationDate
    }
  }

  ${AGREEMENT_SUMMARY_FRAGMENT}
`;
