import { gql } from "@apollo/client";

import { PARTICIPANT_ROLE_FRAGMENT } from "~/graphql/fragments/ParticipantRole";

export const AGREEMENT_FRAGMENT = gql`
  fragment Agreement on Agreement {
    _id
    acknowledgers {
      userId
      date
      user {
        _id
        displayName
        avatar
      }
    }
    context
    needsAcknowledgment
    parent {
      version
      activeVersion
    }
    proposalVersion
    roles {
      ...ParticipantRole
    }
    activeVersion
    status
    expirationDuration
    visibility
    discoverable
    feedbackFrequency
    agreementType
    version
    agreementProperties {
      predefined {
        agreementType
        feedbackFrequency
        expirationDuration
      }
    }
    workspace {
      _id
      name
    }
    workingDocument {
      title
      subtitle
      body
    }
    governs {
      users {
        _id
        displayName
        avatar
      }
      groups {
        _id
        name
        type
        icon
        description
      }
    }
    participants {
      userId
      user {
        _id
        displayName
        avatar
      }
      decision
      role
    }
    proposer {
      _id
      displayName
      avatar
      email
    }
    feedback {
      active
      cycles
    }
  }
  ${PARTICIPANT_ROLE_FRAGMENT}
`;
