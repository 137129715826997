import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import type { DraftFromExistingAgreementInput } from "~/graphql/generated";
import { DRAFT_FROM_EXISTING_AGREEMENT } from "~/graphql/mutations/agreement/draftFromExistingAgreement";

export const useDraftFromExistingAgreement = () => {
  const [draftFromExistingAgreementMutation] = useMutation(
    DRAFT_FROM_EXISTING_AGREEMENT
  );

  const draftFromExistingAgreement = useCallback(
    async (input: DraftFromExistingAgreementInput) => {
      try {
        const { data: raw } = await draftFromExistingAgreementMutation({
          variables: {
            input,
          },
        });

        return { data: raw?.draftFromExistingAgreement };
      } catch (err) {
        throw err;
      }
    },
    [draftFromExistingAgreementMutation]
  );

  return { draftFromExistingAgreement };
};
