import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import { AGREEMENT_SUMMARY_FRAGMENT } from "~/graphql/fragments/agreementSummary";
import type {
  GetArchivedAgreementsQuery,
  GetArchivedAgreementsQueryVariables,
} from "~/graphql/generated";

export const GET_ARCHIVED_AGREEMENTS: TypedDocumentNode<
  GetArchivedAgreementsQuery,
  GetArchivedAgreementsQueryVariables
> = gql`
  query GetArchivedAgreements($agreementsParams: AgreementsParams!) {
    archivedAgreements(params: $agreementsParams) {
      ...AgreementSummary
    }
  }

  ${AGREEMENT_SUMMARY_FRAGMENT}
`;
