import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import type {
  MutationUnarchiveAgreementArgs,
  UnarchiveAgreementMutation,
} from "~/graphql/generated";

export const UNARCHIVE_AGREEMENT: TypedDocumentNode<
  UnarchiveAgreementMutation,
  MutationUnarchiveAgreementArgs
> = gql`
  mutation UnarchiveAgreement($input: UnarchiveAgreementInput!) {
    unarchiveAgreement(input: $input) {
      _id
    }
  }
`;
