import setupArchiveAgreement from "./archiveAgreement";
import setupCreateDraft from "./createDraft";
import setupDeleteDraft from "./deleteDraft";
import setupMarkDraftAsTemplate from "./markDraftAsTemplate";
import setupProposeAgreement from "./proposeAgreement";
import setupUnarchiveAgreement from "./unarchiveAgreement";
import setupUpdateDraft from "./updateDraft";

export const useAgreementActions = () => {
  return {
    createDraft: setupCreateDraft(),
    deleteDraft: setupDeleteDraft(),
    updateDraft: setupUpdateDraft(),
    markDraftAsTemplate: setupMarkDraftAsTemplate(),
    propose: setupProposeAgreement(),
    archive: setupArchiveAgreement(),
    unarchive: setupUnarchiveAgreement(),
  };
};
