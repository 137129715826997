import { Menu, MenuItem, MenuProps, ThemeProvider } from "@mui/material";
import { forwardRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import DefaultCaretIcon from "~/assets/icons/caret.svg";
import { ButtonVariant } from "~/components/Button/types";
import { Button } from "~/components/Modal/styles";
import { mui5Theme } from "~/styles/themes";

type OptionDropdownProps = {
  disabled: boolean;
  onSubmit: () => void;
  options: Options;
  shouldShowDropdown: boolean;
  buttonText: string;
  variant?: ButtonVariant;
  title?: string;
  menuProps?: Partial<MenuProps>;
};

type Option = {
  emoji?: string;
  title: string;
  description: string;
  onSelect: () => void;
  selected: boolean;
};

export type Options = Array<Option>;

const OptionDropdown = forwardRef<HTMLButtonElement, OptionDropdownProps>(
  (
    {
      buttonText,
      disabled,
      onSubmit,
      options,
      shouldShowDropdown,
      variant,
      title,
      menuProps,
    },
    ref
  ) => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const showMenu = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const proposeButtonCSS = {
      ...(shouldShowDropdown && {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: `1px solid ${
          variant === "speech"
            ? theme.colors.murple10Darker
            : theme.colors.moonDark
        }`,
        padding: "0.5rem 0.5rem 0.5rem 1rem",
      }),
    };

    return (
      <div css={{ position: "relative" }}>
        <div css={{ display: "flex" }}>
          <Button
            ref={ref}
            variant={variant}
            disabled={disabled}
            title={title}
            onClick={onSubmit}
            css={proposeButtonCSS}
          >
            {buttonText}
          </Button>
          {shouldShowDropdown && (
            <Button
              variant={variant}
              onClick={handleClick}
              css={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                padding: "0.5rem",
              }}
              disabled={disabled}
            >
              <DefaultCaretIcon width={16} height={16} />
            </Button>
          )}
        </div>
        <ThemeProvider theme={mui5Theme}>
          <StyledMenu
            anchorEl={anchorEl}
            open={showMenu}
            onClose={handleClose}
            {...menuProps}
          >
            {options.map((option) => (
              <MenuItem
                key={option.title}
                onClick={() => {
                  handleClose();
                  option.onSelect();
                }}
                disableRipple={true}
                selected={option.selected}
              >
                <div>
                  <div css={{ fontWeight: theme.weights.medium }}>
                    <span css={{ marginRight: ".25rem" }} aria-hidden="true">
                      {option.emoji}
                    </span>{" "}
                    {option.title}
                  </div>
                  <div css={{ fontSize: "0.8rem", marginTop: "0.25rem" }}>
                    {option.description}
                  </div>
                </div>
              </MenuItem>
            ))}
          </StyledMenu>
        </ThemeProvider>
      </div>
    );
  }
);

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: "-.5rem",
    borderRadius: 4,
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: 0,
      "& > li": {
        padding: "0.5rem 1rem",
        "&.selected": {
          backgroundColor: theme.colors.speech10,
        },
      },
    },
  },
}));

export default OptionDropdown;
