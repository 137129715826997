import { gql } from "@apollo/client";

export const PARTICIPANT_ROLE_FRAGMENT = gql`
  fragment ParticipantRole on ParticipantRole {
    displayName
    linkedEntityId
    type
    participatesAs
    needsAcknowledgement
    inviteEmail
    inviteAsGuest
    avatar
    icon
    members {
      user {
        _id
      }
    }
  }
`;
