import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { ProposeDraftInput } from "~/graphql/generated";
import { PROPOSE_AGREEMENT } from "~/graphql/mutations/agreement/proposeAgreement";

const useSetupProposeAgreement = () => {
  const [proposeAgreementMutation] = useMutation(PROPOSE_AGREEMENT);

  const proposeAgreement = useCallback(
    async (input: ProposeDraftInput) => {
      const { data, errors } = await proposeAgreementMutation({
        variables: {
          _id: input._id ?? "",
          input,
        },
      });

      return { draftAgreement: data?.proposeAgreement, errors };
    },
    [proposeAgreementMutation]
  );

  return proposeAgreement;
};

export default useSetupProposeAgreement;
