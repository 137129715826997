import { useRouter } from "next/router";
import { useCallback } from "react";

import { useAgreementActions } from "~/graphql/hooks/agreement/useAgreementActions";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

export function useCreateDraft() {
  const workspaceSlug = useWorkspaceSlug();

  const router = useRouter();

  const { createDraft } = useAgreementActions();

  const createDraftAndRedirect = useCallback(async () => {
    const result = await createDraft({ workspaceId: workspaceSlug });
    if (result.errors) {
      console.error(result.errors);
      return;
    }

    router.push(
      `/${workspaceSlug}/drafts/${result.data?._id}/rev/${result.data?.version}`
    );
  }, [createDraft, router, workspaceSlug]);

  return { createDraftAndRedirect };
}
