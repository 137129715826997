import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import ErrorSnackbar from "~/components/ErrorSnackbar";
import OptionDropdown from "~/components/ProposerModals/ProposeModal/OptionDropdown";
import { useUserContext } from "~/contexts/UserContext";
import { useDraftsFromExistingAgreementsMutation } from "~/graphql/generated";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";

export const UseTemplateButton = ({ templateId }: { templateId: string }) => {
  const { user } = useUserContext();
  const { push } = useRouter();
  const workspaceSlug = useWorkspaceSlug();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<string | null>(
    workspaceSlug || user?.workspaces?.[0]?._id || null
  );
  const [error, setError] = useState<Error | null>(null);
  const [
    mutate,
    { data: submitResult, error: submitError },
  ] = useDraftsFromExistingAgreementsMutation();

  useEffect(() => {
    if (submitError) {
      setError(submitError);
    }
  }, [submitError]);

  const onOptionSelected = useCallback(
    (selectedWorkspaceId: string) => () => {
      setSelectedWorkspaceId(selectedWorkspaceId);
    },
    [setSelectedWorkspaceId]
  );

  const onSubmit = useCallback(async () => {
    if (!selectedWorkspaceId) {
      setError(new Error("Please select a workspace"));
      return;
    }

    mutate({
      variables: {
        input: {
          agreementIds: [templateId],
          workspaceId: selectedWorkspaceId,
        },
      },
    }).catch((err) => setError(err));
  }, [mutate, selectedWorkspaceId, templateId]);

  useEffect(() => {
    if (submitResult) {
      push(
        `/${selectedWorkspaceId}/drafts/${submitResult?.draftsFromExistingAgreements?.[0]._id}`
      );
    }
  }, [push, selectedWorkspaceId, submitResult]);

  if (!user?.workspaces) {
    return null;
  }

  return (
    <>
      <OptionDropdown
        variant="speech"
        disabled={false}
        options={user.workspaces.map((workspace) => {
          return {
            title: workspace.name,
            description: "",
            onSelect: onOptionSelected(workspace._id),
            selected: false,
          };
        })}
        shouldShowDropdown={true}
        buttonText={
          "Use Template" +
          (user.workspaces.length > 1
            ? ` (${
                (
                  user.workspaces.find(
                    (workspace) => workspace._id === selectedWorkspaceId
                  ) || user.workspaces[0]
                )?.name
              })`
            : "")
        }
        onSubmit={onSubmit}
        menuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
      />
      <ErrorSnackbar open={!!error} onClose={() => setError(null)}>
        {error?.message || ""}
      </ErrorSnackbar>
    </>
  );
};
