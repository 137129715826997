import type { TypedDocumentNode } from "@apollo/client";
import { gql } from "@apollo/client";

import { DRAFT_FRAGMENT } from "~/graphql/fragments/draft";
import type {
  MarkDraftAsTemplateMutation,
  MarkDraftAsTemplateMutationVariables,
} from "~/graphql/generated";

export const MARK_DRAFT_AS_TEMPLATE: TypedDocumentNode<
  MarkDraftAsTemplateMutation,
  MarkDraftAsTemplateMutationVariables
> = gql`
  mutation MarkDraftAsTemplate($_id: String!) {
    markDraftAsTemplate(_id: $_id) {
      ...Draft
    }
  }
  ${DRAFT_FRAGMENT}
`;
