import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import type {
  DeleteDraftMutation,
  DeleteDraftMutationVariables,
} from "~/graphql/generated";

export const DELETE_DRAFT: TypedDocumentNode<
  DeleteDraftMutation,
  DeleteDraftMutationVariables
> = gql`
  mutation DeleteDraft($input: DeleteDraftInput!) {
    deleteDraft(input: $input) {
      _id
    }
  }
`;
