import { useRouter } from "next/router";

/**
 * Getting the agreement `slug` is used frequently in our app
 */
export default function useAgreementSlug() {
  const { query } = useRouter();
  const { slug } = query;
  const agreementSlug = slug || "";

  return agreementSlug as string;
}
