import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import { DRAFT_SUMMARY_FRAGMENT } from "~/graphql/fragments/draftSummary";
import type {
  GetDraftsQuery,
  GetDraftsQueryVariables,
} from "~/graphql/generated";

export const GET_DRAFTS: TypedDocumentNode<
  GetDraftsQuery,
  GetDraftsQueryVariables
> = gql`
  query GetDrafts($agreementsParams: AgreementsParams!) {
    drafts(params: $agreementsParams) {
      ...DraftSummary
    }
  }

  ${DRAFT_SUMMARY_FRAGMENT}
`;
